<template>
    <r-e-dialog title="导入房源" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh"
                @click-submit="clickSubmit" @click-cancel="clickSubmit" @close="clickSubmit">
        <el-upload
            name="file"
            :action="uploadInterfaceIp"
            :on-exceed="handleExceed"
            :before-upload="handleBeforeUpload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :limit="limit"
            :accept="accept"
            :on-success="handleSuccess"
            :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传{{ accept }}文件，且不超过2mb</div>
        </el-upload>
    </r-e-dialog>
</template>

<script>
import { baseConfig } from "@/utils/config";
import { tokenCookies } from "@storage/cookies";
import { MessageSuccess, MessageWarning } from "@custom/message";
export default {
    name: "layer-existing-listings-import",
    data(){
        return {
            dialogVisible: false,
            uploadInterfaceIp: baseConfig.baseURL + "/api/gzf/ready/apartment/import",
            headersToken: {
                "X-Access-Token": tokenCookies.get(),
            },
            fileList: [],
            limit: 1,
            accept: ".xlsx,.xls",
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            loading: null,
        }
    },
    methods:{
        openDialog() {
            this.dialogVisible = true;
        },
        clickSubmit(){
            this.dialogVisible = false;
            this.fileList = [];
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
            MessageWarning(`文件总共只能上传${ this.limit }张，请先删除已上传的文件！`);
        },
        beforeRemove(file, fileList) {
            // return this.$confirm(`确定移除 ${ file.name }？`);
        },
        handleBeforeUpload(file) {
            return new Promise((resolve, reject) => {
                const { name, size } = file;
                const isLt2M = size / 1024 / 1024 < 2;
                // 上传文件格式
                const acceptArray = this.accept.split(",");
                const extensionIndex = name.lastIndexOf(".");       // 后缀序列
                const extension = name.substring(extensionIndex);   // 文件后缀
                this.fileName = name.substring(0, extensionIndex);  // 文件名称
                let isFileType = acceptArray.includes(extension);
                if (!isFileType) {
                    MessageWarning(`请上传${ this.accept }格式的文件！`);
                    reject(false);
                }else if(!isLt2M){
                    MessageWarning(`上传文件大小不能超过 2MB!`);
                    reject(false);
                }
                let that = this;
                const loadingOptions = that.loadingOptions;
                that.loading = that.$loading({...loadingOptions});
                resolve(isFileType);
            });
        },
        handleSuccess(response, file, fileList) {
            // 上传成功
            const { resultCode = -1 } = response;
            if (resultCode === 1000) {
                MessageSuccess("导入成功");
                this.loading.close();
            } else {
                this.handleError();
            }
        },
    }
}
</script>

<style scoped>

</style>